.header {
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
}

.logo {
  width: auto;
  max-height: 80px;
}