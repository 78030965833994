body {
  margin: 0;
  padding: 0;
}

.title {
  word-break: normal;
}

.section,
.hero-body {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.column {
  flex-basis: unset;
}

.card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.card-footer {
  margin-top: auto;
}

.redux-toastr .toastr .close-toastr {
  opacity: 1;
  color: #fff;
}

.redux-toastr .toastr .rrt-left-container {
  width: 75px;
}

.redux-toastr .toastr .rrt-left-container .toastr-icon {
  width: 26px !important;
  height: 26px !important;
}


.redux-toastr .toastr .rrt-middle-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 75px;
  min-height: 70px;
}

.react-datepicker-wrapper,
.react-datepicker__input-container,
.react-datepicker__input-container input {
  display: block;
  width: 100%;
}

.w-100 {
  width: 100%;
}

.wrapperClassName {
  border: 1px solid #F1F1F1;
}

.toolbarClassName {
  border: none;
  border-bottom: 1px solid #F1F1F1;
}

.editorClassName {
  padding: 0 20px;
}

.is-aligned-center {
  align-items: center;
}

.text-center {
  text-align: center;
}

.vertical-align-middle {
  vertical-align: middle !important;
}

.required:after {
  content: '*';
  display: inline-block;
  position: relative;
  top: -4px;
  margin-left: 5px;
  color: red;
  font-size: 11px;
}

.public-DraftStyleDefault-ltr {
  text-align: inherit;
}

.rgpd * {
  display: inline;
}

.tooltip {
  position: relative;
  display: inline-block;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;

  /* Position the tooltip text */
  position: absolute;
  z-index: 1;
  top: -34px;
  right: 0%;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}